<template>
<div>
  <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="flaticon2-document" aria-hidden="true"></i>{{title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                   
                                    <div class="col-xl-12">
                                         <div class="row">
                                               <div class="col-xl-12">
                                                    <span class="confirm-msg">{{this.confirm_message}}</span>
                                                    <div class="confirm-button">
                                                         <el-radio v-model="form.move_radio" label="1">YES</el-radio>
                                                         <el-radio v-model="form.move_radio" label="0">No</el-radio>
                                                    </div>
                                               </div>
                                         </div>
                                        <div class="row" v-if="form.move_radio == '1'">
                                            <div class="col-xl-8">
                                               <el-form-item label="Confirm By" prop="confirm_by">
                                                 <el-input v-model="form.confirm_by" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('confirm_by') ? 'error required':'required'"></el-input>
                                                 <span class="form-err" v-if="form.errors.has('confirm_by')" >Confirm By field is required</span>
                                              </el-form-item>                                                   
                                            </div>                                         
                                        </div>
                                    </div>                                   
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-between border-top pt-10">
                                <a @click="moveProductList" class="text-white" v-if="form.move_radio == '1'">
                                    <div class="btn btn-primary font-weight-bold text-uppercase">
                                       Move
                                    </div>
                                </a>
                                 <a @click="closeForm" class="text-white" v-else>
                                    <div class="btn btn-danger font-weight-bold text-uppercase">
                                       Close
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'create-term',
  props: {
   action_type:{
       type : String,
   },   
   product_id:{
      type : String,
      require:true 
   },
   
  },
  data() {
    return {
      loading : false,
      error:null,
      form : new Form({       
        confirm_by : null,
        move_radio: '1',
        product_id:null,
        action_type:null
      }),
      title:null,
      confirm_message:null
    }
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    moveProductList() {
      let formURL = ''

      if(this.action_type == 'temp_move'){
          formURL = 'posproduct/movePosProduct'
      }else{
          formURL = 'posproduct/inactive/movePosProduct'
      }
      //const msg = 'The term has been created'

      this.form.post(formURL).then((response) => {
        this.$showResponse("success", response.message);
        globalEvents.$emit('movePosProduct', response.data)
        this.$emit('close')
      }).catch((error) => {
        console.log(error);
      })
    }
  },
  created() {
      this.form.product_id = this.product_id;
      this.form.action_type = this.action_type;
      if(this.action_type == 'temp_move'){
          this.title = 'Pos Product Move Product List'
          this.confirm_message = 'Are you sure move to Product List ?'
      }else{
          this.title = 'InActive Move Product List'
          this.confirm_message = 'Are you sure InActive move to Product List ?'
      }
  },
    mounted() {
        if(this.supplier_id){
            this.form.supplier_id = this.supplier_id
        }
    }
}

</script>

<style scoped>
.option-info{
  float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
}
.option-info i {
  color: #27304A;
}
.title{
  font-size: 16px;
  font-weight: 600;
  color: #27304A;
  display: inline-block;
  margin-bottom: 20px;
}
.event-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #ebeef5;
}
.icon-pointer{
  font-size: 20px; cursor: pointer;color: #616e8e;
}
.confirm-msg{
    font-size: 15px;
    font-weight: bold;
    
}
.confirm-button{
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
